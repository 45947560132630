.brand-picker {
  $comp: &;

  @extend %constrain;
  padding: $space;

  @include mq($from: L) {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  h1 {
    color: $darkgrey;
    font-size: $h2;
    margin-bottom: $baseline;
    text-align: center;

    small {
      display: block;
      font-size: 1.25rem;
      letter-spacing: -0.05rem;
      padding-top: 8px;
    }

    @include mq($from: S) {
      margin: 0 0 $baseline * 2;
    }
  }

  &__grid {
    display: grid;
    grid-gap: $space;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));

    @include mq($from: L) {
      max-width: $content-width;
    }
  }

  &__grid-item {
    align-items: center;
    background-color: $white;
    color: var(--color-primary, $darkgrey);
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
    line-height: 1.25;
    padding: $space * 1.5 $space;
    text-align: center;
    text-decoration: none;

    span {
      @extend %visuallyhidden;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  &__grid-image {
    display: block;
    max-height: $space * 3;
    max-width: $space * 10;
    transition: all $transition-speed * 3 $transition-timing;
    width: auto;
  }

  &__dropdown {
    margin-left: auto;
    min-width: 260px;
    position: relative;
  }

  &__dropdown-toggle {
    @extend %field;
    align-items: center;
    background-color: $white;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE2IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNNy4yOTI4OSA4LjcwNzExQzcuNjgzNDIgOS4wOTc2MyA4LjMxNjU4IDkuMDk3NjMgOC43MDcxMSA4LjcwNzExTDE1LjA3MTEgMi4zNDMxNUMxNS40NjE2IDEuOTUyNjIgMTUuNDYxNiAxLjMxOTQ2IDE1LjA3MTEgMC45Mjg5MzJDMTQuNjgwNSAwLjUzODQwOCAxNC4wNDc0IDAuNTM4NDA4IDEzLjY1NjkgMC45Mjg5MzJMOCA2LjU4NTc5TDIuMzQzMTUgMC45Mjg5MzJDMS45NTI2MiAwLjUzODQwOCAxLjMxOTQ2IDAuNTM4NDA4IDAuOTI4OTMyIDAuOTI4OTMyQzAuNTM4NDA4IDEuMzE5NDYgMC41Mzg0MDggMS45NTI2MiAwLjkyODkzMiAyLjM0MzE1TDcuMjkyODkgOC43MDcxMVpNNyA3VjhIOVY3SDdaIiBmaWxsPSIjMDAwMDAwIj48L3BhdGg+DQo8L3N2Zz4NCg==");
    background-position: right 0.6rem center;
    background-repeat: no-repeat;
    border: 0;
    color: $default-text-color;
    cursor: pointer;
    display: flex;
    line-height: normal;
    padding: 4px 30px 4px 8px;
  }

  &__dropdown-links {
    background-color: $white;
    box-shadow: 0 0 20px rgba(19, 30, 41, 0.1);
    color: $default-text-color;
    position: absolute;
    width: 100%;

    a {
      display: block;
      padding: 4px 8px;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &.current {
        opacity: 0.25;
        pointer-events: none;
      }
    }
  }
}
